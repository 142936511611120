<template>
  <div class="btn">
    <span class="title">{{title}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
}
</script>

<style lang="scss" scoped>
.btn {
  font-size: 14px;
  line-height: 14px;
  padding: 10px 20px;
  border-radius: 100px;
  background-image: linear-gradient(90deg, #01ccf6 0%, #10ea8d 100%);
  color: #fff;
}
</style>