<template>
  <div>
    <NavHeader title="好礼详情"></NavHeader>
    <div class="content">

      <div class="banner">
        <img :src="goods.image" mode="widthFix" />
      </div>
      <div class="main-wrap">
        <div class="left">
          <div class="title">{{goods.name}}</div>
          <div class="integral-wrap">
            <span class="integral">{{goods.integral}}</span>
            <span>邻里值</span>
          </div>
        </div>
        <div class="right" @click="goodsTap">
          <xy-gradual-change-btn title="立即兑换"></xy-gradual-change-btn>
        </div>
      </div>

      <div class="content-wrap">
        <div class="title">
          <img class="icon" src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/fengche.svg" mode="widthFix" />
          商品详情
        </div>
        <div class="content" v-html="goods.content"></div>
      </div>

      <van-popup v-model="showPopup" @close="closePopup" class="popupWarp">
        <div class="popup-wrap">
          <div class="close-wrap iconfont iconguanbi" @click="closePopup"></div>
          <div class="image">
            <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/exchange/exchange-success.png" mode="widthFix" v-if="status == 'success'" />
            <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/exchange/exchange-image.png" mode="widthFix" v-else />
          </div>
          <div class="name">{{goods.name}}</div>
          <div class="integral-wrap" v-if="status == 'success'">
            <span class="integral">兑换成功</span>
          </div>
          <div class="integral-wrap" v-else>
            <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/exchange/integral.png" mode="widthFix" class="icon" />
            <span class="integral">{{goods.integral}}</span>
          </div>
          <div class="btn-wrap" v-if="status == 'success'">
            <div class="bnt success" @click="successTap">查看兑换记录</div>
          </div>
          <div class="btn-wrap" v-else>
            <div class="bnt cancel" @click="closePopup">我再想想</div>
            <div class="bnt confirm" @click="confirmTap">立即兑换</div>
          </div>
        </div>
      </van-popup>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavHeader from '@/components/nav-header.vue'
import Footer from '@/components/foot2022.vue'
import XyGradualChangeBtn from '@/components/xy-gradual-change-btn.vue'
import utils from '@/common/utils'

export default {
  name:"exchangeDetail",
  data () {
    return {
      product_id: 0,
      goods: {},
      showPopup: false,
      status: ''
    }
  },
  components: {
    NavHeader,
    Footer,
    XyGradualChangeBtn,
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  watch: {
    '$route': {
      handler: function (to) {
        if (to.name == "exchangeDetail" && to.params.goodsId) {
          this.product_id = to.params.goodsId
          this.getGoods()
        }
      },
      immediate: true
    }
  },
  methods: {
    getGoods: function () {
      let that = this;
      let product_id = that.product_id
      that.$axios.get(that.ocApi + 'points/products/'+ product_id, {})
        .then(res => {
          if (res.data.status == 1) {
              that.goods = {
                image: res.data.data.original_image,
                name: res.data.data.name,
                integral: res.data.data.points,
                content:res.data.data.description
              }
          }
        })
        .catch(error => {
          window.console.log(error)
        });
    },
    goodsTap: utils.debounce(function () {
      this.showPopup = true
    }, 500, true),
    closePopup: utils.debounce(function () {
      this.showPopup=false,
      this.status=''
    }, 500, true),
    confirmTap: utils.debounce(function () {
      let that = this;
      let products = [{ 'product_id': that.product_id, 'quantity': '1', 'cart_select': '1', 'option': '' }]
      that.$axios.post(that.ocApi + 'points/cartorder/create', {
        products: JSON.stringify(products),
      })
        .then(res => {
          if (res.data.status == 1) {
            that.status = "success"
            that.$store.dispatch('updateMember')
          } else {
            that.showPopup=false
            that.$toast.fail(res.data.message)
          }
        })
        .catch(error => {
          window.console.log(error)
        });
    }, 500, true),
    successTap: utils.debounce(function () {
      this.$router.push({ name:'exchangeHistory'});
    }, 500, true),
  },
}
</script>

<style lang="scss" scoped>
.content{
  background-color: #f7f7f7;
}
.banner {
  width: 100%;
}
.banner img {
  width: 100%;
}

.main-wrap {
  background-color: #fff;
  padding: 20px 17px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-wrap .left {
  flex: 1;
}
.main-wrap .left .title {
  font-size: 16px;
  color: #393a3c;
  line-height: 16px;
}
.main-wrap .left .integral-wrap {
  font-size: 11px;
  color: #999999;
  line-height: 11px;
  margin-top: 11px;
}
.main-wrap .left .integral-wrap .integral {
  font-size: 19px;
  line-height: 19px;
  font-weight: bold;
  background: linear-gradient(
    0deg,
    rgba(27, 215, 255, 1) 0%,
    rgba(0, 235, 87, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 5px;
}
.main-wrap .right {
  margin-left: 25px;
}

.content-wrap {
  background-color: #fff;
  margin-top: 10px;
  padding: 0 16px;
}
.content-wrap .title {
  font-size: 15px;
  color: #333333;
  height: 38px;
  line-height: 38px;
  display: flex;
  align-items: center;
}
.content-wrap .title .icon {
  width: 16px;
  margin-right: 5px;
}

.content-wrap .content {
  font-size: 12px;
  color: #646567;
  padding: 5px 0 20px 0;
  line-height: 17px;
  background-color: #fff;
}

.popupWarp .van-popup {
  border-radius: 4px;
}
.popup-wrap {
  width: 300px;
  padding: 35px 16px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup-wrap .close-wrap {
  position: absolute;
  top: 13px;
  right: 13px;
  width: 15px;
  height: 15px;
  font-size: 15px;
  color: #dfdee2;
}
.popup-wrap .image {
  width: 107px;
  height: 107px;
}
.popup-wrap .image img {
  width: 100%;
}
.popup-wrap .name {
  font-size: 18px;
  color: #212121;
  line-height: 18px;
  margin-top: 24px;
}
.popup-wrap .integral-wrap {
  margin-top: 15px;
  display: flex;
}
.popup-wrap .integral-wrap .icon {
  width: 16px;
  margin-right: 4px;
}
.popup-wrap .integral-wrap .integral {
  font-weight: 800;
  background: linear-gradient(
    0deg,
    rgba(27, 215, 255, 1) 0%,
    rgba(0, 235, 87, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  color: #1bd7ff;
  line-height: 18px;
}
.popup-wrap .btn-wrap {
  margin-top: 30px;
  width: 100%;
  display: flex;
}
.popup-wrap .btn-wrap .bnt {
  flex: 1;
  font-size: 17px;
  padding: 14px 0;
  font-weight: bold;
}
.popup-wrap .btn-wrap .bnt.cancel {
  color: #acadb7;
  background-color: #fff;
  border: 1px solid #dfdee2;
  border-radius: 50px 0 0 50px;
  border-right: none;
}
.popup-wrap .btn-wrap .bnt.confirm {
  background-image: linear-gradient(90deg, #01ccf6 0%, #10ea8d 100%);
  color: #fff;
  border: 1px solid transparent;
  border-radius: 0 50px 50px 0;
}
.popup-wrap .btn-wrap .bnt.success {
  background-image: linear-gradient(90deg, #01ccf6 0%, #10ea8d 100%);
  color: #fff;
  border: 1px solid transparent;
  border-radius: 50px;
}
</style>